<template>
  <master-detail
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    :contextOptions="contextOptions"
    :customResource="customResource"
    :disableTableSaveButton="disableTableSaveButton"
    :filterQuery="filterQuery"
    formTitle="Alocar Horas"
    :lastRowData="lastRowData"
    noReloadOnDelete
    :opts="opts"
    @contextmenu="contextHandler"
    @onOpenFormDialog="onOpenFormDialog"
  >
    <div class="d-flex">
      <v-menu
        v-model="periodMenu"
        :close-on-click="false"
        :close-on-content-click="false"
        transition="scroll-y-transition"
        offset-y
        right
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="width: 370px"
            prefix="Período:"
            :value="period | toPeriod"
            dense
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            @click:prepend.stop="on.click"
            v-on="on"
            hide-details
            class="mb-n1 mt-0"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="period"
          range
          no-title
          @mouseenter:date="highlightWeek"
          @mouseleave:date="highlightWeekOver = []"
          :events="highlightWeekOver"
          :event-color="tableColor"
          @click:date="dateClick"
        ></v-date-picker>
      </v-menu>
      <v-autocomplete
        v-if="showColaboradorSelect"
        v-model="colaboradorId"
        :items="opts.colaboradores"
        class="mb-n1 mt-0 ml-3"
        style="width: 370px"
        item-value="id"
        item-text="nome"
        prefix="Colaborador"
        prepend-icon="mdi-account"
        placeholder="Todos"
        dense
        clearable
        hide-details
      ></v-autocomplete>
    </div>

    <GModal ref="confirmDeleteDay" title="Confirmar exclusão do dia">
      <p style="max-width: 500px">
        Deseja excluir o registro do dia <b>{{ selectedItem.dia }}</b> para o
        projeto <b>{{ selectedItem.projeto || "Título não encontrado" }}</b>
        <template v-if="selectedItem.atividade">
          , atividade <b>{{ selectedItem.atividade }}</b>
        </template>
        <template v-if="selectedItem.funcionarioNome">
          , colaborador <b>{{ selectedItem.funcionarioNome }}</b>
        </template>
        ?
      </p>
      <p>Essa ação não pode ser desfeita.</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn class="px-3" color="primary" dark depressed @click="close(true)">
          Sim
        </v-btn>
        <v-spacer />
      </template>
    </GModal>

    <GModal ref="confirmDeleteWeek" title="Confirmar exclusão">
      <p style="max-width: 500px">
        Deseja excluir todos os registros da semana para o projeto
        <b>{{ selectedItem.projeto || "Título não encontrado" }}</b>
        <template v-if="selectedItem.atividade">
          , atividade <b>{{ selectedItem.atividade }}</b>
        </template>
        <template v-if="selectedItem.funcionarioNome">
          , colaborador <b>{{ selectedItem.funcionarioNome }}</b>
        </template>
        ?
      </p>
      <p>Essa ação não pode ser desfeita.</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn class="px-3" color="primary" dark depressed @click="close(true)">
          Sim
        </v-btn>
        <v-spacer />
      </template>
    </GModal>
  </master-detail>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { UserTypeEnum } from "@/core/enums/user-types";
import compareFloatNum from "@/helpers/compareFloatNum";
import { cloneDeep } from "lodash";

export default {
  components: {
    GModal: () => import("@/components/g-modal.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "user"]),
    cols: function () {
      const week = this.weekFromDate(this.period[0]);
      const days = week.map((day) => ({
        key: day,
        name: this.$options.filters.toWeekDay(day),
        type: this.$fieldTypes.TIME,
        editOnTable: true,
        editable: this.dateInsideProjectPeriod(day),
        hideInform: this.dateInsideProjectPeriod(day, true),
        align: 0,
        width: 120,
        colSize: 3,
        commentForField: `comment${day}`,
      }));

      return [
        {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: this.opcoesProjetosParsed, key: "id", name: "titulo" },
          hideInTable: true,
          rules: [{ rule: "required" }],
        },
        {
          key: "projeto",
          name: "Projeto",
          hideInform: true,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "atividadeId",
          name: "Atividade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "atividades", key: "id", name: "atividade" },
          rules: [{ rule: "required" }],
          hideInTable: true,
        },
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "funcionarioId",
          name: "Colaborador",
          hideInform: !!this.funcionarioId,
          hideInTable: true,
          defaultValue: !this.funcionarioId ? [] : [this.funcionarioId],
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "colaboradores", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "colaborador",
          name: "Colaborador",
          hideInform: true,
          hideInTable: !!this.funcionarioId,
          type: this.$fieldTypes.TEXT,
        },
        ...days,
        {
          key: "total",
          name: "Total",
          type: this.$fieldTypes.TIME,
          editable: false,
          hideInform: true,
        },
      ];
    },
    contextOptions() {
      const showDeleteDay = this.showDeleteDay;
      const options = [
        {
          name: "Excluir dia",
          limit: 1,
          show: showDeleteDay,
          cb: async (row, col) => {
            const id = row.datas.find(({ data }) => col.value === data)?.id;

            if (isNaN(id)) {
              return;
            }

            this.selectedItem = {
              atividade: row.atividade,
              dia: col.text,
              funcionarioNome: row.funcionarioNome,
              projeto: row.projeto,
            };
            const confirm = await this.$refs.confirmDeleteDay.asyncOpen();
            this.selectedItem = {};

            if (!confirm) {
              return;
            }

            await this.customResource.deleteById(row.originalData, id);
            row[col.value] = null;
          },
        },
        {
          name: "Excluir semana",
          limit: 1,
          show: true,
          cb: async (row) => {
            this.selectedItem = {
              atividade: row.atividade,
              funcionarioNome: row.funcionarioNome,
              projeto: row.projeto,
            };
            const confirm = await this.$refs.confirmDeleteWeek.asyncOpen();
            this.selectedItem = {};

            if (!confirm) {
              return;
            }

            const timesheet = this.formatTimeSheet(row);
            this.customResource.deleteWeek(timesheet, row.originalData);
          },
        },
      ];

      return options.filter(({ show }) => show);
    },
    customResource: function () {
      const url = `/v1/timesheet/${this.clientId}/semanal`;
      const resource = this.apiResource(url);
      return {
        ...resource,
        get: (...args) => {
          return resource.get(...args).then((response) => {
            const safeTimesheets = Array.isArray(response) ? response : [];
            this.timeSheetsOfWeek = safeTimesheets;
            this.parsedTimeSheetsOfWeek = this.parseTimeSheet(safeTimesheets);
            return this.parsedTimeSheetsOfWeek;
          });
        },
        save: async (obj) => {
          const body = this.formatTimeSheet(obj);
          const { save } = this.apiResource(`/v1/timesheet/${this.clientId}`);
          const response = await save(body);
          const index = this.timeSheetsOfWeek.indexOf(obj.originalData);

          if (index >= 0) {
            this.timeSheetsOfWeek.splice(index, 1, body);
          } else {
            this.timeSheetsOfWeek.push(body);
          }

          this.timeSheetsOfWeek = cloneDeep(this.timeSheetsOfWeek);
          return response;
        },
        // Desativar o delete padrão dos apiResource
        delete: async () => {
          return null;
        },
        deleteById: async (originalData, id) => {
          const PROCESS_NAME = `alocar-horas-deleteById`;
          try {
            this.$root.$emit("addLoadingProcess", PROCESS_NAME);
            const { funcionarioId } = originalData;
  
            if (!id || isNaN(id)) {
              throw new Error(`sheetId ${id} não é um valor válido!`);
            }
  
            if (!funcionarioId || isNaN(funcionarioId)) {
              throw new Error(`funcionarioId ${funcionarioId} não é um valor válido!`);
            }
  
            const resource = this.apiResource(`/v1/timesheet/${this.clientId}/${funcionarioId}`);
            const response = await resource.delete(id);
            const index = this.timeSheetsOfWeek.indexOf(originalData);
  
            if (index >= 0) {
              const next = cloneDeep(originalData);
              next.datas = next.datas.filter(({ id: dataId }) => dataId !== id);
              this.timeSheetsOfWeek.splice(index, 1, next);
            }
  
            this.timeSheetsOfWeek = cloneDeep(this.timeSheetsOfWeek);
            return response;
          } catch (error) {
            return error;
          } finally {
            this.$root.$emit("removeLoadingProcess", PROCESS_NAME);
          }
        },
        deleteWeek: async (timesheet, originalData) => {
          // Esse dele não recebe um id, mas um body igual ao save
          if (typeof timesheet !== "object") {
            return;
          }

          const PROCESS_NAME = `alocar-horas-deleteWeek`;

          try {
            this.$root.$emit("addLoadingProcess", PROCESS_NAME);
            const { funcionarioId } = originalData;
            const { datas } = timesheet;
            const response = await this.api().delete(`/v1/timesheet/${this.clientId}/${funcionarioId}`, { data: { datas } });
            const index = this.timeSheetsOfWeek.indexOf(originalData);
  
            if (index >= 0) {
              this.timeSheetsOfWeek.splice(index, 1);
            }
  
            this.timeSheetsOfWeek = cloneDeep(this.timeSheetsOfWeek);
            return response;
          } catch (error) {
            return error;
          } finally {
            this.$root.$emit("removeLoadingProcess", PROCESS_NAME);
          }
        },
      };
    },
    filterQuery: function () {
      const [dataIni, dataFim] = this.period;
      return `dataIni=${dataIni}&dataFim=${dataFim}${
        this.colaboradorId ? `&funcionarioId=${this.colaboradorId}` : ""
      }`;
    },
    funcionarioId: function () {
      return this.user.funcionarioId;
    },
    lastRowData: function () {
      const week = this.weekFromDate(this.period[0]);
      const totals = week.reduce((prev, date) => ({ ...prev, [date]: 0 }), {
        colaborador: "Total",
      });
      return this.parsedTimeSheetsOfWeek.reduce((acc, date) => {
        return week.reduce(
          (prev, day) => ({ ...prev, [day]: prev[day] + (date[day] ?? 0) }),
          acc
        );
      }, totals);
    },
    opcoesProjetosParsed: function () {
      const week = this.weekFromDate(this.period[0]);
      const periodoInicial = week[0];
      const periodoFinal = week[6];
      return this.opts.projetos
        .map(({ data_fim, data_inicio, id, titulo, ...rest }) => {
          const disabled =
            data_inicio &&
            data_fim &&
            !(
              data_inicio <= periodoInicial &&
              periodoInicial <= data_fim &&
              data_inicio <= periodoFinal &&
              periodoFinal <= data_fim
            );
          const safeTitle =
            (titulo || "Titulo não encontrado") +
            (disabled ? " (Projeto já finalizado)" : "");
          return {
            data_fim,
            data_inicio,
            disabled,
            id,
            titulo: safeTitle,
            ...rest,
          };
        })
        .sort((a, b) => a.titulo.localeCompare(b.titulo));
    },
    resourceAtividades: function () {
      return this.apiResource(`/v1/timesheet/${this.clientId}/atividades`);
    },
    resourceColaboradores: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/selecao`);
    },
    showColaboradorSelect: function () {
      return this.user.tipo_usuario != UserTypeEnum.COLABORADOR;
    },
  },
  created: function () {
    let dataIni = this.$route.query.dataIni;
    let dataFim = this.$route.query.dataFim;

    if (dataIni && dataFim) {
      this.period = [dataIni, dataFim];
    } else {
      this.period = this.getFilters("period") || this.getWeekPeriod(moment());
    }

    this.resourceColaboradores.get().then((response) => {
      this.opts.colaboradores = response.colaboradores
        .sort((a, b) => a.nome.localeCompare(b.nome))
        .map((col) => ({ ...col, nome: `${col.matricula} — ${col.nome}` }));
    });

    this.resourceAtividades.get().then((response) => {
      this.opts.atividades = response;
    });
  },
  data: function () {
    return {
      colaboradorId: null,
      period: [],
      periodMenu: false,
      timeSheetsOfWeek: [],
      parsedTimeSheetsOfWeek: [],
      highlightWeekOver: [],
      selectedItem: {},
      showDeleteDay: false,
      opts: {
        atividades: [],
        colaboradores: [],
        projetos: [],
        projetosCadastro: [],
      },
    };
  },
  methods: {
    contextHandler(_row, _event, _expanded, col) {
      this.showDeleteDay = moment(col.value, "YYYY-MM-DD").isValid();
    },
    dateClick: function (day) {
      this.period = this.getWeekPeriod(moment(day, "YYYY-MM-DD"));
      this.periodMenu = this.period.length != 2;
    },
    dateInsideProjectPeriod: function (date, inverse = false) {
      return (linha) => {
        if (!linha || !Array.isArray(this.opts.projetos)) {
          return true;
        }

        const { projetoId } = linha;
        const projeto = this.opts.projetos.find(({ id }) => id === projetoId);

        if (!projeto) {
          return true;
        }

        const { data_inicio, data_fim } = projeto;
        const res =
          !data_inicio ||
          !data_fim ||
          (data_inicio <= date && date <= data_fim);

        return inverse ? !res : res;
      };
    },
    disableTableSaveButton: function (row) {
      const exist = this.timeSheetsOfWeek.find(
        ({ atividadeId, funcionarioId, projetoId }) =>
          atividadeId == row.atividadeId &&
          funcionarioId == row.funcionarioId &&
          projetoId == row.projetoId
      );
      const datas = Object.values(exist.datas);
      const result = !this.weekFromDate(this.period[0]).some((day) => {
        const timesheet = datas.find(({ data }) => data === day);

        if (!timesheet) {
          return row[day] > 0;
        }

        const tempoPrev = timesheet?.tempo || 0;
        const tempoPos = row[day] || 0;
        const comentarioPrev = timesheet?.comentario ?? "";
        const comentarioPos = row[`comment${day}`] ?? "";
        return (
          !compareFloatNum(tempoPos, tempoPrev) ||
          comentarioPos.localeCompare(comentarioPrev)
        );
      });

      return result;
    },
    getWeekPeriod: function (date) {
      let anoBase = date.get('year');
      let today = moment(date);
      let start = today.day(0);
      let end = moment(start).day(6);

      if (start.get('year') !== anoBase) {
        start = moment(`${anoBase}-01-01`, "YYYY-MM-DD");
      }
      
      if (end.get('year') !== anoBase) {
        end = moment(`${anoBase}-12-31`, "YYYY-MM-DD");
      }

      return [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")];
    },
    getProjetosByYear: async function (year) {
      try {
        const resource = this.apiResource(
          `/v1/projetos/${this.clientId}/selecao?ano=${year}`
        );
        const response = await resource.get();

        if (response.error) {
          throw response;
        }

        const projetos = Array.isArray(response.projetos)
          ? response.projetos
          : [];
        this.opts.projetos = projetos;
      } catch (error) {
        this.opts.projetos = [];
        throw error;
      }
    },
    highlightWeek: function (date) {
      this.highlightWeekOver = this.weekFromDate(date);
    },
    onOpenFormDialog: function () {
      const [dataIni] = this.period;
      const ano = moment(dataIni).format("YYYY");
      this.getProjetosByYear(ano);
    },
    formatTimeSheet: function (row) {
      const week = this.weekFromDate(this.period[0]);
      const { datas, ...props } = row;
      const nextDatas = week.reduce((acc, day) => {
        if (day in props && props[day] > 0) {
          let found =
            Object.values(datas ?? {}).find(({ data }) => data === day) ?? {};
          return [
            ...acc,
            {
              ...found,
              data: day,
              tempo: props[day],
              comentario: props[`comment${day}`] ?? "",
            },
          ];
        }

        return acc;
      }, []);

      week.forEach((day) => {
        delete props[day];
        delete props[`comment${day}`];
      });
      delete props.colaborador;
      delete props.originalData;
      delete props.total;

      // tipo 1 indica ser um timesheet diário (sendo 2 um timesheet mensal)
      return { ...props, datas: nextDatas, tipo: 1 };
    },
    parseTimeSheet: function (timesheets) {
      if (!Array.isArray(timesheets)) {
        return [];
      }

      return timesheets.map((weeksheet) => {
        const datas = weeksheet.datas ? Object.values(weeksheet.datas) : [];
        return {
          ...weeksheet,
          colaborador: `${
            weeksheet.funcionarioMatricula ?? "SEM MATRICULA"
          } — ${weeksheet.funcionarioNome}`,
          originalData: weeksheet,
          total: datas.reduce((acc, { tempo }) => acc + tempo, 0),
          ...datas.reduce(
            (prev, { data, tempo, comentario }) => ({
              ...prev,
              [data]: tempo,
              [`comment${data}`]: comentario,
            }),
            {}
          ),
        };
      });
    },
    weekFromDate: function (date) {
      let today = moment(date);
      let [start, end] = this.getWeekPeriod(today);
      let size = moment(end).diff(moment(start), 'days');
      return new Array(size + 1).fill(start).map((_, index) => moment(start).add(index, 'days').format("YYYY-MM-DD"));
    },
  },
  watch: {
    period: function (value) {
      this.setFilters({ period: value });
    },
  },
};
</script>